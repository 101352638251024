import React from "react"
import CardBlogArchive from "./post-cards"
import { Grid } from "semantic-ui-react"

const AllPosts = () => {
  return (
    <div>
      <Grid
        style={{ margin: "50px 0" }}
        columns={3}
        stackable
        padded="horizontally"
      >
        <Grid.Row>
          <Grid.Column>
            <CardBlogArchive />
          </Grid.Column>
          <Grid.Column>
            <CardBlogArchive />
          </Grid.Column>
          <Grid.Column>
            <CardBlogArchive />
          </Grid.Column>
          <Grid.Column>
            <CardBlogArchive />
          </Grid.Column>
          <Grid.Column>
            <CardBlogArchive />
          </Grid.Column>
          <Grid.Column>
            <CardBlogArchive />
          </Grid.Column>
          <Grid.Column>
            <CardBlogArchive />
          </Grid.Column>
          <Grid.Column>
            <CardBlogArchive />
          </Grid.Column>
          <Grid.Column>
            <CardBlogArchive />
          </Grid.Column>
          <Grid.Column>
            <CardBlogArchive />
          </Grid.Column>
          <Grid.Column>
            <CardBlogArchive />
          </Grid.Column>
          <Grid.Column>
            <CardBlogArchive />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default AllPosts
