import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AllPosts from "../components/PostArchive/all-posts"
import HeroSectionPostArchivePage from "../components/PostArchive/hero-section"
// import { Link } from "gatsby"

const PostArchive = () => (
  <Layout>
    <SEO title="All Posts | My Food Book" />
    <div>
      <HeroSectionPostArchivePage />
      <AllPosts />
    </div>
  </Layout>
)

export default PostArchive
