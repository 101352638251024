import React from "react"
import { Divider } from "semantic-ui-react"
import Fade from "react-reveal/Fade"

const HeroSectionPostArchivePage = () => {
  return (
    <div>
      <Fade>
        <div className="hero-image-post-archive">
          <div className="hero-text">
            <Fade top>
              <h1>Browse All Posts</h1>
              <Divider style={{ borderColor: "#ffa41b" }} />
            </Fade>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default HeroSectionPostArchivePage
